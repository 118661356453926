@import-normalize;

/* Global styles */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

* {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

*, *::after, *::before {
  box-sizing: border-box;
}

.transition-fill {
  transition: fill 0.3s ease;
}

.transition-all {
  transition: all 0.3s ease;
}

.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}